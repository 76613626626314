import styled from "styled-components";
import { orange, pink } from "../../bosons/colors";

export const FooterContainer = styled.div`
  padding: 24px;
`;

export const FooterContent = styled.div`
  text-align: center;
  > img {
    width: 48px;
  }
  > p {
    font-size: 12px;
  }
`;

export const FooterSocial = styled.div`
  a {
    display: inline-block;
    padding: 8px;
  }
  img {
    width: 32px;
  }
`;

export const FooterMenu = styled.div`
  > a {
    font-size: 12px;
    color: ${pink.dark};
    margin: 4px;
  }
`;
