/* eslint-disable react/prop-types */
import React from "react";
import { Helmet } from "react-helmet";
import { createGlobalStyle } from "styled-components";
import Footer from "../../organisms/Footer";
import { HomeContainer } from "./styles";

import Caixa1 from "../../../assets/caixa1.webp";
import Caixa2 from "../../../assets/caixa2.webp";
import Caixa3 from "../../../assets/caixa3.webp";
import Caixa4 from "../../../assets/caixa4.webp";

import Logo from "../../../assets/signed-logo.webp";

const GlobalStyle = createGlobalStyle`
  @import url("https://fonts.googleapis.com/css2?family=Spartan:wght@400;600&display=swap");

  body {
    margin: 0;
    font-family: "Spartan", sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  b {
    font-weight: 600;
  }
`;

const Main = ({ children, title, description, canonical }) => (
  <HomeContainer>
    <GlobalStyle />
    <Helmet
      htmlAttributes={{
        lang: "pt-br",
      }}
    >
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta
        name="facebook-domain-verification"
        content="6o8xgvhqolp0zbeayf4zec8dud9a9r"
      />
      <link rel="canonical" href={canonical} />
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "Product",
          "url": "https://somosgenti.com.br/",
          "name": "Caixas para presentear",
          "description": "experiência de presentear com gentileza em forma de caixa, algo tão especial que não cabe em uma cesta de café da manhã",
          "offers": [
            {
              "@type": "Offer",
              "price": 149,
              "priceCurrency": "BRL",
              "availability": "https://schema.org/InStock",
              "url": "/",
              "itemOffered": {
                "@type": "Thing",
                "name": "Caixa 01",
                "image": "${Caixa1}",
                "description": "Caixa para presentear contendo: pão artesanal, café, suco natural, queijo, iogurte com fruta, granola artesanal, geleia e manteiga"
              }
            },
            {
              "@type": "Offer",
              "price": 179,
              "priceCurrency": "BRL",
              "availability": "https://schema.org/InStock",
              "url": "/",
              "itemOffered": {
                "@type": "Thing",
                "name": "Caixa 02",
                "image": "${Caixa2}",
                "description": "Caixa para presentear contendo: pão artesanal, café, suco natural, queijo, iogurte com fruta, granola artesanal, geleia, manteiga, bolo artesanal, waffle artesanal, mel e fruta"
              }
            },
            {
              "@type": "Offer",
              "price": 189,
              "priceCurrency": "BRL",
              "availability": "https://schema.org/InStock",
              "url": "/",
              "itemOffered": {
                "@type": "Thing",
                "name": "Caixa 03",
                "image": "${Caixa3}",
                "description": "Caixa para presentear contendo: pão artesanal, café, suco natural, queijo, iogurte com fruta, granola artesanal, geleia, manteiga, bolo artesanal, avocado, ovo mexido e bacon em tiras ou tomate cereja"
              }
            },
            {
              "@type": "Offer",
              "price": 189,
              "priceCurrency": "BRL",
              "availability": "https://schema.org/InStock",
              "url": "/",
              "itemOffered": {
                "@type": "Thing",
                "name": "Caixa 04",
                "image": "${Caixa4}",
                "description": "Caixa para presentear contendo: pão artesanal, café, suco natural, queijo, iogurte com fruta, granola artesanal, geleia, manteiga, bolo artesanal, croissant e frios"
              }
            }
          ],
          "brand": {
            "@type": "Organization",
            "brand": {
              "@type": "Brand",
              "logo": "${Logo}",
              "name": "Genti",
              "slogan": "Caixas para presentear"
            },
            "telephone": "+5531981214293",
            "name": "Genti",
            "slogan": "Caixas para presentear"
          }
        }
      `}
      </script>
    </Helmet>
    {children}
    <Footer />
  </HomeContainer>
);

export default Main;
