export const white = {
  default: "#fff",
};

export const black = {
  default: "#000",
};

export const primary = {
  default: "#971233",
};

export const orange = {
  light: "#FDE1C0",
  default: "#FBBE65",
  dark: "#AD722B",
};

export const green = {
  light: "#BED3CA",
  default: "#426E60",
  dark: "#2C3833",
};

export const pink = {
  light: "#FCE3DC",
  default: "#E3AFA5",
  dark: "#AF6258",
};

export const red = {
  light: "#E2CFCC",
  default: "#6F4137",
  dark: "#3F211E",
};
