import React from "react";
import { Link } from "gatsby";
import {
  FooterContainer,
  FooterContent,
  FooterMenu,
  FooterSocial,
} from "./styles";
import Instagram from "../../../assets/instagram.webp";
import Faceboook from "../../../assets/facebook.webp";

function Footer() {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterSocial>
          <a
            href="https://www.instagram.com/somosgenti/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Instagram} alt="Instagram" />
          </a>{" "}
          <a
            href="https://www.facebook.com/somosgenti/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Faceboook} alt="Facebook" />
          </a>
        </FooterSocial>
        <p>© Copyright 2021 - Genti - Caixas para presentear</p>
        <p>
          Todos os direitos reservados com Genti - Caixas para presentear CNPJ
          36.322.003/0001-99
        </p>
        <p>Savassi, Belo Horizonte/MG - CEP 30.112-000</p>
        <FooterMenu>
          <Link to="/termos">Termos de uso</Link>
          <Link to="/privacidade">Política de privacidade</Link>
        </FooterMenu>
      </FooterContent>
    </FooterContainer>
  );
}

export default Footer;
